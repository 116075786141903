<template>
 <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
   <!-- 分类 -->
   <div>
    <van-notice-bar color="#1989fa" background="#ecf9ff">
      功能介绍：过滤掉不必要的号码，最大化投资回报率.
    </van-notice-bar>
    <!-- <p style="opacity:0.8;background-color: white;color:rgba(14, 160, 239, 0.945);font-size: 13px;text-align: center;margin: 0.2rem 0rem;">功能介绍：过滤掉不必要的号码，最大化投资回报率。</p> -->
    <h6>请选择玩法
        <span style="opacity:0.5;font-size: 13px;">(大底：该玩法下的所有号码)</span>
    </h6>
   </div>
    <van-grid :border="false" :column-num="2">
    <van-grid-item class="my-grid-item" v-for="(item, index) in firstPlay" :key="index" @click="goDetail(index+1,item.name)">
        <p><span class="name" :style="{backgroundColor:item.color}">{{item.name.slice(0,2)}}</span> 
            <span class="placehold">{{item.data}}</span>
        </p>
    </van-grid-item>
    </van-grid>
 </div>
</template>

<script>
    import vHeader from "@/components/header.vue";
    import config from "../../../package.json";

export default {
    
    name:'prize',
    data(){
        return{
            color:'rgb(241, 106, 241)',
            title:'包奖王',
            firstPlay:[
                {
                    name:'四定添加条件',
                    num:4,
                    type:'fourfixed',
                    data:'大底：10000组',
                    color:'orange'
                    
                },
                {
                    name:'四现添加条件',
                    num:4,
                    type:'fourshow',
                    data:'大底：715组',
                    color:'orange'
                },
                {
                    name:'三定添加条件',
                    num:3,
                    type:'threefixed',
                    data:'大底：4000组',
                    color:'rgb(133, 52, 194)'
                },
                {
                    name:'三现添加条件',
                    num:3,
                    type:'threeshow',
                    data:'大底：220组',
                    color:'rgb(133, 52, 194)'
                },
                {
                    name:'二定添加条件',
                    num:2,
                    type:'twofixed',
                    data:'大底：600组',
                    color:'rgb(241, 106, 241)'
                },
                {
                    name:'二现添加条件',
                    num:2,
                    type:'twoshow',
                    data:'大底：55组',
                    color:'rgb(241, 106, 241)'
                },
               
            ]
        }
    },
    methods:{
        goDetail(n,title){
            switch (n) {
                case 1:
                if (this.$store.state.prize.Cfourfixed.length === 0)
                {
                    this.$store.commit('prize/alertFourfixed',this.$store.state.prize.fourfixed);
                }
                this.$store.commit('prize/updatePayType',1);

                break;
                case 2:
                if (this.$store.state.prize.Cfourshow.length === 0)
                {
                    this.$store.commit('prize/alertFourshow',this.$store.state.prize.fourshow);
                }
                this.$store.commit('prize/updatePayType',2);

                break;
                case 3:
                if (this.$store.state.prize.Cthreefixed.length === 0)
                {
                    this.$store.commit('prize/alertThreefixed',this.$store.state.prize.threefixed);
                }
                this.$store.commit('prize/updatePayType',3);

                break;
                case 4:
                if (this.$store.state.prize.Cthreeshow.length === 0)
                {
                    this.$store.commit('prize/alertThreeshow',this.$store.state.prize.threeshow);
                }
                this.$store.commit('prize/updatePayType',4);

                break;
                case 5:
                if (this.$store.state.prize.Ctwofixed.length === 0)
                {
                    this.$store.commit('prize/alertTwofixed',this.$store.state.prize.twofixed);
                }
                this.$store.commit('prize/updatePayType',5);

                break;
                case 6:
                if (this.$store.state.prize.Ctwoshow.length === 0)
                {
                    this.$store.commit('prize/alertTwoshow',this.$store.state.prize.twoshow);
                }
                this.$store.commit('prize/updatePayType',6);
                
                break;
        
            }

            this.$router.push({
                                name:'prizeDetail',
                                query:{
                                    title:title
                                }
                            })
        }
    },
    components: {
        vHeader
    },
    mounted(){
        // 刷新vuex
       
        if (this.$store.state.prize.version !== config.version || this.$store.state.prize.version === undefined){
            this.$store.commit('prize/clearAllData');
            this.$store.commit('prize/alertVersion',config.version);
            this.location.reload();
        }
    }

}
</script>

<style scoped>
*{
    padding: 0%;
    margin: 0%;
}
.name{
    
    width: 40px;
    height: 30px;
    font-size: 0.6rem;
    margin-right: 8px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    color: white;

    
  
}
.placehold{
    font-size: 8px;
  
}
.my-grid-item{

    padding: 0.08rem;
   
}
.item{
    
    width: 4rem;
    height: 1rem;
    /* text-align: center; */
    margin: 0.1rem 0rem;
}
</style>